import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LayoutModule } from '@angular/cdk/layout';
import {
  MatButtonModule,
  MatMenuModule,
  MatToolbarModule,
  MatIconModule,
  MatCardModule,
  MatStepperModule,
  MatFormFieldModule,
  MatInputModule,
  MatGridListModule,
  MatSelectModule,
  MatTableModule,
  MatPaginatorModule,
  MatSortModule,
  MatDialogModule,
  MatSnackBarModule,
  MatSlideToggleModule,
  MatBadgeModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatProgressBarModule,
  MatCheckboxModule,
  MatSidenavModule,
  MatListModule,
  MatTabsModule,
  MatRadioModule,
  MatProgressSpinnerModule,
  MatSliderModule,
  MatAutocompleteModule,
  MatExpansionModule,
  MatButtonToggleModule,
  MatChipsModule,
  MatTooltipModule,
  MatBottomSheetModule,
  MatPaginatorIntl,
} from '@angular/material';
import {MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA} from '@angular/material/bottom-sheet';  
import { MatCarouselModule } from '@ngmodule/material-carousel';

@NgModule({
  exports: [
    FormsModule,
    ReactiveFormsModule,
    LayoutModule,
    MatCheckboxModule,
    MatCheckboxModule,
    MatButtonModule,
    MatInputModule,
    MatStepperModule,
    MatAutocompleteModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatRadioModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatTabsModule,
    MatExpansionModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    MatDialogModule,
    MatTooltipModule,
    MatSnackBarModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatBadgeModule,
    MatNativeDateModule,
    MatBottomSheetModule,
    MatCarouselModule
  ],
  providers:[
    { provide: MatBottomSheetRef, useValue: {} },
    { provide: MAT_BOTTOM_SHEET_DATA, useValue: {} }
  ]
})
export class MaterialModule { }
