export class FileModel{
    id: number = null;
    url: string = null;
    caption: string = null;
    tipo: string = null;
    mime: string = null;
    estatus: boolean = true;
    createdAt: Date;
    updatedAt: Date;

}