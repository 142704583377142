import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-privacy-and-policy',
  templateUrl: './privacy-and-policy.component.html',
  styleUrls: ['./privacy-and-policy.component.css']
})
export class PrivacyAndPolicyComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
