export class ZoomMeetingTypes{
  dataset:any=[
    { key:1, value:"Meeting instantaneo" },
    { key:2, value:"Meeting agendado" },
    { key:3, value:"Meeting recurrente sin límite de tiempo" },
    { key:5, value:"Webinar" },
    { key:6, value:"Webinar recurrente sin límite de tiempo" },
    { key:8, value:"Meeting recurrente con límite de tiempo" },
    { key:9, value:"Webinar recurrente con límite de tiempo" },
  ];
}
