export class DaysList{
  dataset: any = [
    { key: 1, value: "Domingo" },
    { key: 2, value: "Lunes" },
    { key: 3, value: "Martes" },
    { key: 4, value: "Miércoles" },
    { key: 5, value: "Jueves" },
    { key: 6, value: "Viernes" },
    { key: 7, value: "Sábado" }
  ];
}
