export class CatalogoModel {
  id: number;
  tipoCatalogo: string;
  nombre: string;
  activo: boolean;
  createdAt: Date;
  updatedAt: Date;
  idCatalogoPadre: number;
}

/* Tipos de catalogos:
AREAS
CARGOS
CLASE_MINUTA
ESTATUS
ESTATUS_PROYECTOS
FUENTES
IDIOMAS
MODELOS
ORIGEN
PAIS
PARQUES_INDUSTRIALES
PERFIL-USUARIO
PERIODICIDAD
PRIORIDAD_PROYECTO
PROBABILIDAD
REGIONES
SECTORES
SERVICIOS
SOLICITUD_ESTATUS
SPACS_SECTORES
SPACS_SUBSECTORES
SUBSECTORES
TEMA_MINUTA
TIPO_EMPRESA
TIPO_MESA_NEGOCIACION
TIPO_MINUTA
TIPO_PRODUCTOR
*/