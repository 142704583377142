import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-encuesta-modal',
  templateUrl: './encuesta-modal.component.html',
  styleUrls: ['./encuesta-modal.component.css']
})
export class EncuestaModalComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
