import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-institutional-login',
  templateUrl: './institutional-login.component.html',
  styleUrls: ['./institutional-login.component.css']
})
export class InstitutionalLoginComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
