import {Component, Inject, OnInit} from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import {MAT_DIALOG_DATA} from '@angular/material';

@Component({
  selector: 'app-modal-eliminar-meetings-cuenta',
  templateUrl: './modal-eliminar-meetings-cuenta.component.html',
  styleUrls: ['./modal-eliminar-meetings-cuenta.component.css']
})
export class ModalEliminarMeetingsCuentaComponent implements OnInit {

  meeting: any;

  constructor(
    private dialogReference: MatDialogRef<ModalEliminarMeetingsCuentaComponent>,
    @Inject(MAT_DIALOG_DATA) private data
  ) { }

  ngOnInit() {
    console.log(this.data.meeting.apiResponse);
    this.meeting = JSON.parse(this.data.meeting.apiResponse);
  }

}
